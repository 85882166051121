import Title from "components/landing/Title";
import TeamCard from "components/landing/TeamCard";
import Image1 from "assets/img/1.png";
import Image2 from "assets/img/2.png";
import Image3 from "assets/img/3.png";

//import Image4 from 'assets/img/team-4-470x470.png';
import Button from "@material-tailwind/react/Button";
import { MailIcon, IdentificationIcon } from "@heroicons/react/solid";
export default function MarketingSection() {
  return (
    <section className="pt-10 pb-30 pb-20 relative block bg-white grid justify-center">
      <div className="container flex flex-wrap place-content-center place-items-center ">
        <div className="font-bold p-6 text-blue-900 text-2xl text-center uppercase">
          {" "}
          <span>
            {" "}
            <IdentificationIcon className="mr-2 h-9 w-9 px-2 shadow-lg text-white rounded-full  bg-primary inline-block" />{" "}
            <b className="text-primary"> About </b>{" "}
            <b className="text-secondary"> us </b>{" "}
          </span>{" "}
        </div>
        <Title>
          {/* <Button
            color="transparent"
            className="bg-blue-900 text-white ml-4 container max-w-7xl mx-auto px-4"
            ripple="dark"
          >
            About Us
          </Button> */}
          {/* <br />
          <br /> */}
          Atlas Strategic Technologies is a Premier Agency Management software
          platform designed for Insurance Professionals to manage the customer
          lifecycle from lead generation through Point of Sale. The Atlas
          software provides its users the tools and processes necessary to drive
          customer growth, retention, and servicing activities needed to drive
          your customer's satisfaction and insurance buying experience.
        </Title>

        <div className="flex flex-wrap place-content-center place-items-center ">
          <TeamCard
            img={Image1}
            // name="Ryan Tompson"
            position="Take your agency to the next level through automation, simplification, and enhanced customer experience."
          />
          <TeamCard
            img={Image2}
            // name="Romina Hadid"
            position="Highly configurable software allows you to create the customer experience you desire for your type of agency"
          />
          <TeamCard
            img={Image3}
            // name="Alexa Smith"
            position="Have more insights to your business through reporting, analytics and integrations into your core BI tools"
          />
          {/* <TeamCard
                        img={Image4}
                        name="Jenna Kardi"
                        position="Founder and CEO"
                    /> */}
        </div>
      </div>
    </section>
  );
}
