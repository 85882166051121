import H5 from "@material-tailwind/react/Heading5";
import LeadText from "@material-tailwind/react/LeadText";
import Icon from "@material-tailwind/react/Icon";

export default function DefaultFooter() {
  return (
    <>
      <footer className="relative bg-primary pt-8 pb-6 ">
        <div className="container max-w-7xl mx-auto px-4 ">
          <div className="flex flex-wrap text-center pt-6 justify-center ">
            <div className="w-full lg:w-6/12 px-4 text-white">
              <b color="white">Atlas Strategic Technologies</b>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full  px-4 mx-auto text-center">
            <div className="text-sm text-white font-medium py-1">
              {/* Copyright © {new Date().getFullYear()}  */}
              2022 Confie Holding II Co.
              <br />
              Trade/Service marks are the property of Confie Holding II Co or
              its respective affiliates and/or subsidiaries
              <br />
              <section className=" relative py-4  space-x-2 grid grid-cols-1 place-content-center w-auto place-items-center text-center  justify-center">
                <div className="grid  lg:grid-cols-2 xl:grid-cols-2sm:grid-rows-2   gap-12  p-4 place-items-center ">
                  <a
                    href="https://www.confie.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white font-medium block pb-2 text-sm"
                  >
                    <u>Privacy Statement</u>
                  </a>

                  <a
                    href="https://www.confie.com/california-consumer-privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white font-medium block pb-2 text-sm"
                  >
                    <u> CA Privacy Policy</u>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
