import Title from "components/landing/Title";
import ContactCard from "components/landing/ContactCard";

import Button from "@material-tailwind/react/Button";

import { MailIcon, PhoneIcon } from "@heroicons/react/solid";
import Office from "assets/img/Office.png";
export default function ContactSection() {
  return (
    <section className=" relative py-4  bg-gray-100  grid grid-cols-1 place-content-center w-auto place-items-center text-center  justify-center">
      <div className="grid  lg:grid-cols-2 xl:grid-cols-2sm:grid-rows-2    gap-2  p-4 place-items-center  ">
        <div className="font-bold p-6 text-blue-900 text-2xl text-center uppercase">
          {" "}
          <span>
            {" "}
            <MailIcon className="mr-2 h-9 w-9 px-2 shadow-lg text-white rounded-full  bg-blue-900 inline-block" />{" "}
            <b className="text-primary"> Contact </b>{" "}
            <b className="text-secondary"> us </b>{" "}
          </span>{" "}
        </div>
        <div class="mb-10 grid justify-center ">
          <div className="border-2 w-64 p-4 place-self-center border-solid border-secondary rounded-t-lg text-center text-base font-semibold">
            <div className="mb-3">
              <div>
                <label>7711 Center Ave Suite 200</label>
              </div>
              <div className="">
                <label>Huntington Beach, CA 92647</label>
              </div>
            </div>
            <div class="flex flex-cols gap-2 justify-center px-3">
              <div>
                <MailIcon className="h-6 w-6 text-primary" />
              </div>
              <div>
                <label className="text-xs">
                  info@atlasstrategictechnologies.com
                </label>
              </div>
            </div>
          </div>
          <div className=" bg-primary flex flex-cols relative heightOffice widthOffice rounded-md">
            <label className="text-white whitespace-nowrap place-self-center text-lg font-semibold px-10 py-8">
              Main Office
            </label>
            <div className="">
              <img
                class=" absolute top-10 left-54 rounded-full h-24 w-24"
                src={Office}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
