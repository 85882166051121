import { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "@material-tailwind/react/Navbar";
import NavbarContainer from "@material-tailwind/react/NavbarContainer";
import NavbarWrapper from "@material-tailwind/react/NavbarWrapper";
import NavbarBrand from "@material-tailwind/react/NavbarBrand";
import NavbarToggler from "@material-tailwind/react/NavbarToggler";
import NavbarCollapse from "@material-tailwind/react/NavbarCollapse";
import Nav from "@material-tailwind/react/Nav";
import NavLink from "@material-tailwind/react/NavLink";
import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";
import Icon from "@material-tailwind/react/Icon";
import Button from "@material-tailwind/react/Button";
import atlasLogo from "../assets/img/atlasLogo.png";
import AtlasLogoMain from "assets/img/AtlasLogoMain.png";
export default function DefaultNavbar() {
  const [openNavbar, setOpenNavbar] = useState(false);

  return (
    <Navbar color="transparent" navbar className="sticky top-0 z-50 shadow-lg ">
      <NavbarContainer className="lg:px-24 sm:px-4">
        <NavbarWrapper>
          <a
            href="https://atlasstrategictechnologies.com/"
            // target="_blank"
            rel="noreferrer"
          >
            <img class="block w-28" src={AtlasLogoMain} alt="Atlas" />
            {/* <NavbarBrand className="text-black">Material Tailwind</NavbarBrand>
             */}
          </a>

          <NavbarToggler
            onClick={() => setOpenNavbar(!openNavbar)}
            color="blue"
          />
        </NavbarWrapper>

        <NavbarCollapse open={openNavbar}>
          <Nav>
            <div className=" flex flex-col z-50 lg:flex-row  ">
              <a href="" target="_blank" rel="noreferrer">
                <Button
                  color="transparent"
                  className="bg-primary text-white ml-4 font-mundial"
                  ripple="dark"
                >
                  Contact Us
                </Button>
              </a>
            </div>
          </Nav>
        </NavbarCollapse>
      </NavbarContainer>
    </Navbar>
  );
}
