import H2 from "@material-tailwind/react/Heading2";
import LeadText from "@material-tailwind/react/LeadText";
import mainPhoto from "assets/img/mainPhoto.png";
export default function Header() {
  return (
    <div className="relative flex content-center items-center justify-center  ">
      <div className=" bg-cover bg-center absolute top-0 w-full h-full" />
      <div className="container max-w-6xl relative ">
        {/* <H2 color="white">Your story starts with us.</H2> */}
        <div class="grid justify-center">
          <img src={mainPhoto} class="buttonHeight mainWidth object-fit" />
        </div>
      </div>
    </div>
  );
}
