import { Switch, Route, Redirect } from "react-router-dom";
import Landing from "pages/Landing";
import "assets/font/mundial-cufonfonts/MundialRegular.otf";

// Font Awesome Style Sheet
import "@fortawesome/fontawesome-free/css/all.min.css";

// Tailwind CSS Style Sheet
import "assets/styles/tailwind.css";
import "App.css";

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Redirect from="*" to="/" />
    </Switch>
  );
}

export default App;
