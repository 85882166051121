import DefaultNavbar from "components/DefaultNavbar";
import DefaultFooter from "components/DefaultFooter";
import Header from "components/landing/Header";
//import WorkingSection from 'components/landing/WorkingSection';
import MarketingSection from "components/landing/MarketingSection";
import ContactSection from "components/landing/ContactSection";

export default function Landing() {
  return (
    <>
      <div className="bg-white  w-full sticky top-0 z-50  lg:overflow-y-visible ">
        <DefaultNavbar />
      </div>
      <main className="relative ">
        <Header />
        <MarketingSection />
        <ContactSection />
      </main>
      <DefaultFooter />
    </>
  );
}
