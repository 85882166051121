import Image from "@material-tailwind/react/Image";
import H6 from "@material-tailwind/react/Heading6";
import Paragraph from "@material-tailwind/react/Paragraph";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";

export default function LandingTeamCard({ img, name, position }) {
  return (
    <div className=" w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4 ">
      <div className="px-6  ">
        <br />
        <Image src={img} alt={name} raised className="rounded-t-lg" />
        {/* <div className="absolute rounded-lg text-xs p-1 -mt-2 mx-3 text-white font-semibold bg-blue-600">
          Text here
        </div> */}
        <div className="shadow-lg py-6 text-center bg-primary rounded-b-xl border-b-8 border-secondary text-white  px-4 ">
          <H6 color="gray">{name}</H6>
          <Paragraph color="white">{position}</Paragraph>
        </div>
      </div>
    </div>
  );
}
